import React from "react"
// import { StoreContext } from "../../context/store-context"

const ProductPrice = ({ price, sale, compareAt, cardTextColour }) => {
  return sale ? (
    <span className="space-x-1.5">
      <span className="line-through" style={{ color: cardTextColour }}>
        {compareAt}
      </span>

      <span className="text-red">{price}</span>
    </span>
  ) : (
    <span style={{ color: cardTextColour }}>{price} </span>
  )
}

export default ProductPrice
