import React from "react"

const TextTitle = ({ title, className }) => {
  return (
    <header className={`h-70px lg:h-90px flex items-center padding text-large leading-none  bg-white ${className}`}>
      <span className="-mx-0.5">{title}</span>
    </header>
  )
}

export default TextTitle
