import React from "react"
import ProductCard from "./ProductCard"
import { useStaticQuery, graphql } from "gatsby"
const ProductGrid = ({ grid, products }) => {
  const data = useStaticQuery(graphql`
    {
      allSanitySettings {
        nodes {
          cardColour {
            hex
          }
        }
      }
    }
  `)
  const settings = data.allSanitySettings.nodes[0].cardColour

  const filteredProducts = products
  // const filteredProducts = products.filter(p => p.archived === false)
  return (
    <div className="relative z-10 w-full pb-px overflow-hidden ">
      <div
        className={`${
          grid ? "md:grid-cols-" + grid : "lg:grid-cols-4"
        } grid grid-cols-2`}
      >
        {filteredProducts.map((product, index) => {
          const sale = product.store?.variants[0]?.store.compareAtPrice
          const featuredTag = product.featuredTag

          return (
            <ProductCard
              product={product}
              key={index}
              sale={sale ? true : false}
              settings={settings}
              collaboration={featuredTag ? featuredTag : ""}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ProductGrid
