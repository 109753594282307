import React from "react"
const ProductMeta = ({
  oos,
  sale,
  collaboration,
  className,
  cardTextColour,
}) => {
  return (
    <header
      className={` z-10 flex justify-end leading-none ${className}`}
      style={{ color: cardTextColour }}
    >
      {oos ? (
        <span>Out of stock</span>
      ) : (
        <span
          className={`flex w-full space-x-6 ${
            sale && collaboration ? "justify-between" : "justify-end"
          }`}
        >
          {sale && <span className="uppercase text-red">Sale</span>}
          {/* {collaboration && (
            <span style={{ color: cardTextColour }}>{collaboration}</span>
          )} */}
        </span>
      )}
    </header>
  )
}

export default ProductMeta
