import React from "react"
import { Link, graphql } from "gatsby"
import ProductMeta from "../product/ProductMeta"
import ProductPrice from "../product/ProductPrice"

import { cell } from "./productCard.module.scss"
import { currencyHook } from "../../utils/currencyHook"
import { StoreContext } from "../../context/store-context"
import { FetchHook } from "../../utils/fetchHook"

const ProductCard = ({
  product,

  sale,
  collaboration,
  style,
  settings,
}) => {
  // console.log(collaboration)
  const { currencyCode } = React.useContext(StoreContext)

  const { fetchedProduct, fetchedImages } = FetchHook(product.store.id)

  var price
  var compareAt
  var oos
  var imageOne
  var imageTwo

  const cardTextColour = product?.cardTextColour?.hex

  if (fetchedProduct) {
    // console.log(fetchedProduct)
    const fallbackPrices = [
      {
        price: {
          currencyCode: "GBP",
          amount: product.store.priceRange.minVariantPrice,
        },
      },
    ]
    const presentmentPrices = fetchedProduct?.variants[0]?.presentmentPrices
    const { currencyConversion } = currencyHook(
      presentmentPrices ? presentmentPrices : fallbackPrices
    )
    price = currencyConversion(
      product.store.priceRange.minVariantPrice,
      currencyCode
    )

    compareAt = currencyConversion(
      fetchedProduct.variants[0].compareAtPrice,
      currencyCode
    )
    oos = !fetchedProduct.availableForSale

    imageOne = product.cardImages?.image
      ? product?.cardImages?.image?.asset?.url
      : fetchedImages !== null
      ? fetchedImages[0]?.src
      : "https://dummyimage.com/600x400/ffffff/a6a6a6"
    imageTwo = product.cardImages?.hoverImage
      ? product.cardImages.hoverImage.asset.url
      : fetchedImages && fetchedImages.length >= 2
      ? fetchedImages[1]?.src
      : null
  }
  return (
    <div
      className={`relative overflow-hidden border-b border-t -mb-px md:border-none flex flex-col md:-mr-px  ${cell} justify-stretch md:aspect-w-4 md:aspect-h-5`}
      style={style}
    >
      <Link
        to={`/store/${product.store.slug.current}`}
        className="flex flex-col w-full h-full "
        style={{ backgroundColor: `${settings.hex}` }}
      >
        <ProductMeta
          cardTextColour={cardTextColour}
          oos={oos}
          collaboration={collaboration && collaboration.store.title}
          sale={sale}
          className="absolute leading-none text-right pointer-events-none p-15px md:p-20px group md:relative"
        />
        <figure className="relative flex items-center justify-center w-full border-b border-gray-200 group md:border-none md:absolute aspect-w-4 aspect-h-5">
          {imageOne && (
            <img
              src={imageOne}
              alt="product"
              className={`top-0 left-0 object-contain transition-opacity duration-300 w-full h-full ${
                imageTwo ? "md:group-hover:opacity-0" : ""
              } `}
            />
          )}
          {imageTwo && (
            <img
              src={imageTwo}
              alt="product"
              className="absolute top-0 left-0 object-cover w-full h-full transition-opacity duration-300 opacity-0 md:group-hover:opacity-100"
            />
          )}
        </figure>
        <footer
          className={`relative z-10 hidden md:flex flex-col md:flex-row bg-white md:bg-transparent md:items-center md:justify-between py-10px px-15px md:p-20px leading-tight flex-1 md:mt-auto md:flex-initial  h-full md:h-auto ${
            oos ? "text-gray-300" : ""
          }`}
        >
          <span className="mb-px">{product.store.title}</span>
          <ProductPrice
            cardTextColour={cardTextColour}
            price={price}
            compareAt={compareAt}
            sale={sale}
          />
        </footer>
        <footer
          className={`relative z-10 flex md:hidden flex-col md:flex-row bg-white md:bg-transparent md:items-center md:justify-between py-10px px-15px md:p-20px leading-none md:mt-auto md:flex-initial h-full md:h-auto ${
            oos ? "text-gray-300" : ""
          }`}
        >
          <span className="mb-px">{product.store.title}</span>
          <ProductPrice price={price} compareAt={compareAt} sale={sale} />
        </footer>
      </Link>
    </div>
  )
}

export default ProductCard

export const query = graphql`
  fragment SaneShop on SanityProduct {
    _id
    featuredTag {
      store {
        title
        slug {
          current
        }
      }
    }
    description {
      style
      list
      children {
        _key
        _type
        marks
        text
      }
      _type
      _key
    }
    cardTextColour {
      hex
    }
    cardImages {
      hoverImage {
        asset {
          url
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      image {
        asset {
          url
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
    information {
      _key
      _type
      content {
        style
        list
        _type
        _key
        children {
          text
          marks
          _type
          _key
        }
      }
      title
    }
    store {
      id
      isDeleted
      priceRange {
        minVariantPrice
        maxVariantPrice
        _key
      }
      slug {
        current
      }
      productType
      title
      tags
      status
      variants {
        id
        store {
          isDeleted
          price
          previewImageUrl
          compareAtPrice
          title
          status
          option1
          option2
        }
      }
    }
    images {
      asset {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        url
      }
    }
    recommended {
      images {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      store {
        title
        isDeleted
        status
        tags
        priceRange {
          minVariantPrice
          maxVariantPrice
        }
      }
    }
  }
`
