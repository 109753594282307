import { useState, useEffect, useContext } from "react"

import { StoreContext } from "../context/store-context"
import { Buffer } from "buffer"
export function FetchHook(id) {
  const { client } = useContext(StoreContext)

  const [fetchedProduct, setfetchedProduct] = useState(null)

  const [fetchedImages, setImages] = useState(null)

  let productId = "gid://shopify/Product/" + id
  let buf = Buffer.from(productId, "utf8")
  productId = buf.toString("base64")
  useEffect(() => {
    client.product.fetch(productId).then(product => {
      setfetchedProduct(product)
      setImages(product?.images)
    })
  }, [client.product, productId])

  return {
    fetchedProduct,
    fetchedImages,
  }
}
