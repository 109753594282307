import getSymbolFromCurrency from "currency-symbol-map"
export function currencyHook(presentmentPrice) {
  var prices = []
  if (presentmentPrice) {
    presentmentPrice.forEach(p => {
      prices.push({
        code: p.price.currencyCode,
        price: Number(p.price.amount),
      })
    })
  }
  const defaultCode = prices.find(p => p.code === "GBP")

  var currencies = []

  prices.forEach(p => {
    const rate = p.price / defaultCode.price

    currencies.push({
      code: p.code,
      rate: rate,
    })
  })
  const currencyConversion = (price, currencyCode) => {
    const currency = currencies.find(c => c.code === currencyCode)
    const conversion = Number(price) * currency?.rate

    // const rate = (1.5 / 100) * conversion
    // const total = Math.floor(conversion, 1)
    // console.log(total)

    const symbol = getSymbolFromCurrency(currencyCode)
    const amount = new Intl.NumberFormat("en-US", {
      style: "decimal",
      // minimumFractionDigits: 2,
    }).format(conversion)
    const formatPrice = symbol + amount
    return formatPrice
  }
  return {
    currencyConversion,
    currencies,
  }
}
